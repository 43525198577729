<template>
  <div class="changePassword">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="用户名：">{{ name }}</el-form-item>
      <el-form-item label="旧密码：" prop="oldPassword">
        <el-input
          type="password"
          v-model="ruleForm.oldPassword"
          placeholder="请输入旧密码"
          @input="handleOnKeyup('oldPassword')"
        ></el-input>
      </el-form-item>
      <el-form-item label="新密码：" prop="newPassword">
        <el-input
          type="password"
          v-model="ruleForm.newPassword"
          placeholder="请输入新密码"
          @input="handleOnKeyup('newPassword')"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          style="margin-right: 20px"
          type="primary"
          @click="submitForm('ruleForm')"
          >修改</el-button
        >
        <el-button @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "changePassword",
  data() {
    return {
      name: JSON.parse(window.sessionStorage.getItem("userInfo")).name,
      ruleForm: {
        id: JSON.parse(window.sessionStorage.getItem("userInfo")).userId,
      },
      rules: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    handleOnKeyup(type) {
      console.log(this[type]);
      this.ruleForm[type] = this.handleKeyup(this.ruleForm[type]);
    },
    reset() {
      this.ruleForm.oldPassword = "";
      this.ruleForm.newPassword = "";
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await this.$api.getUserModifyPwd(this.ruleForm);
          this.$message({
            message: res.message,
            type: res.status === 200 ? "success" : "warning",
            duration: 1500,
          });
          res.status === 200 && this.close();
        }
      });
    },
    close() {
      sessionStorage.clear();
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-form {
  width: 20% !important;
  margin: 0 auto !important;
  .el-form-item__content {
    text-align: left !important;
  }
}
.changePassword {
  display: inline-block;
  width: calc(100% - 40px);
  min-height: calc(100vh - 114px);
  margin: 20px;
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff;
  text-align: center;
}
</style>
